import React from "react";

export default class SectionHeading extends React.Component {
    render() {
    return (
      <div className='cg-sectionHeading'>
        <h1>
          {this.props.children}
        </h1>
        <hr style={{maxWidth: '8rem', margin: '1rem auto'}}/>
      </div>
    );
  }
}